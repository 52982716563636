/* markdown-style */

.myComponent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.myComponent img {
  width: 90%;
  height: 30rem;
  object-fit: cover;
  display: block;
  margin: auto;
}

.myComponent h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.myComponent h3 {
  font-size: 24px;
  font-weight: bold;
}

.myComponent h2 {
  color: #333;
}

.myComponent div {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.myComponent em {
  font-size: xx-large;
  font-style: italic;
  display: flex;
  justify-content: center;
}

.myComponent p {
  margin-bottom: 1em;
}

.myComponent ol {
  margin-bottom: 1em;
}

.myComponent li {
  margin-bottom: 0.5em;
}

.myComponent strong {
  font-weight: bold;
}
