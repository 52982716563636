@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* border: solid 1px grey; */
  scroll-behavior: smooth;
}
@media only screen and (max-width: 600px) {
  .media {
    visibility: hidden;
  }
}

@media only screen and (max-width: 1216px) {
  .flexFix {
    flex-wrap: wrap;
    border: none;
  }
}

@media only screen and (max-width: 600px) {
  .media-show {
    visibility: visible;
  }

  .animation-slide {
    animation-name: slide;
    animation-duration: 1s;
    height: 100vh;
  }
  .animation-slide-isClose {
    animation-name: slideClose;
    animation-duration: 1s;
    visibility: hidden;
    opacity: 0;
  }
}
@media only screen and (min-width: 601px) {
  .media-show {
    visibility: hidden;
  }
}

@keyframes slide {
  from {
    opacity: 0;
    visibility: hidden;
    height: 0vh;
  }
  to {
    opacity: 1;
    height: 100vh;
    visibility: visible;
  }
}

@keyframes slideClose {
  from {
    opacity: 1;
    visibility: visible;
    height: 100vh;
    top: auto;
  }
  to {
    opacity: 0;
    visibility: hidden;
    height: 0vh;
  }
}

.bg_slider {
  --initialTextColor: #000000;
  --slideTextColor: #ffffff;

  --initialBgColor: #fff;
  --slideBgColor: #000000;

  color: var(--initialTextColor);

  background-image: linear-gradient(
    90deg,
    var(--initialBgColor) 50%,
    var(--slideBgColor) 50%,
    var(--slideBgColor) 100%
  );
  background-size: 200%;
  transition: background-position 0.3s cubic-bezier(0.47, 0.1, 1, 0.63),
    color 0.2s linear;
  transition-delay: 0s, 0.15s;
}

.bg_slider:hover {
  color: var(--slideTextColor);
  cursor: pointer;
  background-position: -100%;
}
.section {
  padding: 5rem 3rem;
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

body {
  font-family: "Poppins", sans-serif;
}
